<template>
	<v-card class="mb-4 elevation-5">
		<ConfirmTool ref="confirm"></ConfirmTool>

		<v-container fluid>
			<v-row dense>
				<v-col>
					<h6 class="text-h6">
						<v-icon>fas fa-calendar-alt</v-icon>
						<span> {{ booking.BookingTime | formatDateTime }}</span>
					</h6>
				</v-col>
				<v-col class="text-right">
					<nutriYouBookingTypeChip :StatusId="booking.BookingTypeId"></nutriYouBookingTypeChip>
				</v-col>
			</v-row>
			<v-row dense><v-col>
					<UserAvatarFullname style="display: inline-block" :showEmailIdentifier="true"
						:profile="booking.Patient">
					</UserAvatarFullname>
					<br v-if="$vuetify.breakpoint.xs" />
					<v-btn color="primary" class="text-capitalize" :class="{'ml-4': !$vuetify.breakpoint.xs }"
						:to="'/Chat?UserId=' + booking.PatientUserId">
						<v-icon left>fas fa-comment-alt-lines</v-icon>
						Chat</v-btn>
				</v-col>
				<v-col class="text-right">
					<span class="text-h5">
						{{ booking.Price | formatCurrency }}</span>
					<div v-if="booking.Discount">
						Sconto: {{ booking.Discount | formatCurrency }}
						<br />
						Importo Pagato:
						{{ booking.TotalPaymentAmount | formatCurrency }}

					</div>
					<span class="font-weight-bold">
						{{ booking.Duration }} minuti</span>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col>
					<nutriYouOrderStatusChip :StatusId="booking.BookingStatusId"></nutriYouOrderStatusChip>
					<span class="ml-2 font-weight-bold grey--text">
						Id: {{ booking.BookingId }} </span>
				</v-col>
				<v-col cols="12" md="6">
					<v-alert type="info" v-if="booking.IsPaidToNewStripeAccount" class="font-weight-bold">
						Da fatturare direttamente al paziente il giorno della visita

						<v-btn text color="white" dark @click="ShowBillingInfo">Dati di Fatturazione</v-btn>
						<v-dialog max-width="400" v-model="showBillingInfoDialog">
							<v-card>
								<v-card-title>{{ $t("billingInfo") }}</v-card-title>
								<v-card-text v-if="LoadingBillingInfo">
									<v-progress-linear indeterminate></v-progress-linear>
								</v-card-text>
								<v-card-text v-if="BillingProfile">
									<div class="mb-3 text--primary">
										<span class="font-weight-bold pr-1">{{ $t("common.name") }}:</span>{{
										BillingProfile.RagioneSociale }}
										<br />
										<span class="font-weight-bold pr-1">{{ $t("common.fiscalCode") }}:</span>{{
										BillingProfile.CodiceFiscale }}<br />
										<div v-if="BillingProfile.PartitaIva">
											<span class="font-weight-bold pr-1">{{ $t("common.pIva") }}:</span>{{
											BillingProfile.PartitaIva }}<br />
										</div>
										<span class="font-weight-bold pr-1"> {{ $t("common.address") }}:</span>
										{{ BillingProfile.Address }} <br />
										{{ BillingProfile.PostalCode }} {{ BillingProfile.Comune.Name }} ({{
										BillingProfile.Comune.ProvinciaAbbreviation }})

									</div>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn @click="showBillingInfoDialog = false;" color="primary" text>{{
										$t("common.close") }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-alert>
          <v-alert type="info" v-if="booking.IsPaidToNutriyouStripeAccount">
            Fatturato da Nutriyou
          </v-alert>
				</v-col>
			</v-row>
		</v-container>

		<v-card-actions>
			<v-spacer></v-spacer>

			<template v-if="
				booking.BookingStatusId === BookingStatusIdEnum.BOOKED ||
				booking.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT
			">
				<v-btn color="error" text @click="ShowCancelBookingDialog" :loading="loadingData">{{
					$t("common.cancel")
					}}</v-btn>
				<v-btn color="info" text @click="openModifyBookingDialog" :loading="loadingData">{{
					$t("common.edit")
					}}</v-btn>

			</template>
			<template v-if="
				booking.BookingStatusId === BookingStatusIdEnum.BOOKED
			"><v-btn color="info" @click="CompleteBooking" :loading="loadingData">{{
					$t("CompleteBooking")
					}}</v-btn></template>
		</v-card-actions>
		<v-dialog max-width="400" v-model="showCancelBookingDialog">
			<v-card>
				<v-card-title>{{ $t("CancelBooking") }} </v-card-title>
				<v-card-text>
					Stai per annullare l'appuntamento.

					<br />
					<strong>
						ATTENZIONE: se invece vuoi spostare questo appuntamento, NON CANCELLARLO ma premi sul pulsante
						MODIFICA.</strong>
					<br />
					<v-select :items="cancelReasons" label="Seleziona il motivo dell'annullamento:"
						v-model="selectedCancelReason" item-text="Text" item-value="Value">

					</v-select>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showCancelBookingDialog = false">{{ $t("common.back") }}</v-btn>
					<v-btn color="error" @click="CancelBooking" :loading="loadingData"
						:disabled="selectedCancelReason === null">{{
						$t("common.cancel")
						}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog max-width="400" v-model="showModifyDialog"><v-card><v-card-title>Modifica la data e ora
				</v-card-title>
				<v-card-text>
					<DateTimePicker v-model="newDataTime"></DateTimePicker>
					<!-- {{ booking.BookingTime | formatDateTime }} -->
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="showModifyDialog = false" text>{{
						$t("common.cancel")
						}}</v-btn>
					<v-btn @click="UpdateDateTime" color="primary">{{
						$t("common.update")
						}}</v-btn>
				</v-card-actions>
			</v-card></v-dialog>
	</v-card>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip";
import nutriYouBookingTypeChip from "@/components/NutriYou/nutriYouBookingTypeChip";
import DateTimePicker from "@/components/Shared/FormControl/DateTimePicker";

import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";

export default {
	components: {
		UserAvatarFullname,
		nutriYouOrderStatusChip,
		nutriYouBookingTypeChip,
		ConfirmTool,
		DateTimePicker,

	},
	props: {
		booking: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showModifyDialog: false,
			newDataTime: null,
			loadingData: false,
			showCancelBookingDialog: false,
			selectedCancelReason: null,
			cancelReasons: [
				{ Value: 2, Text: "Annullato per scelta del paziente" },
				{ Value: 3, Text: "Paziente non era presente" },
				{ Value: 5, Text: "Annullato per riprogrammare in futuro" },
				{ Value: 4, Text: "Appuntamento creato per errore" },
				{ Value: 1, Text: "Annullato per scelta del nutrizionista" }
			],
			showBillingInfoDialog: false,
			BillingProfile: null,
			LoadingBillingInfo: false
		};
	},
	created() {
		this.BookingStatusIdEnum = BookingStatusIdEnum;
		this.BookingTypesEnum = BookingTypesEnum;
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.PatientsService = new CrudClient("CurrentNutritionist/Patients");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async ShowBillingInfo() {
			try {
				this.LoadingBillingInfo = true;
				this.showBillingInfoDialog = true;
				this.BillingProfile = null;
				const billingProfiles = await this.PatientsService.Get(this.booking.PatientUserId + "/BillingProfiles")

				if (billingProfiles.Data.length > 0) {
					this.BillingProfile = billingProfiles.Data[0];
				}
			} catch (error) {
				this.showBillingInfoDialog = false;
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingBillingInfo = false;
			}
		},

		ShowCancelBookingDialog() {
			this.showCancelBookingDialog = true;

			if (this.booking.BookingTypeId !== BookingTypesEnum.FREE) {
				this.cancelReasons = this.cancelReasons.filter(r => r.Value !== 3);
			}
		},

		async CancelBooking() {
			try {
				this.loadingData = true;
				await this.BookingsService.Post(
					this.booking.BookingId,
					null,
					"CancelBooking?cancelReasonId=" + this.selectedCancelReason
				);
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingData = false;
				this.showCancelBookingDialog = false;
			}
		},

		openModifyBookingDialog() {
			this.showModifyDialog = true;
			this.newDataTime = this.booking.BookingTime;
		},

		async UpdateDateTime() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("ModifyBooking"),
					"Sei sicuro di voler modificare la data dell'appuntamento?",
					{ color: "warning" }
				))
			) {
				return 1;
			}
			try {
				this.loadingData = true;
				const dto = { BookingTime: this.newDataTime };
				await this.BookingsService.Post(
					this.booking.BookingId,
					dto,
					"ModifyBookingTime"
				);
				this.$log.debug("bookingModified");
				this.$emit("bookingModified");

				this.showModifyDialog = false;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingData = false;
			}
		},

		async CompleteBooking() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("CompleteBooking"),
					"Sei sicuro di voler completare l'appuntamento?",
					{ color: "warning" }
				))
			) {
				return 1;
			}
			try {
				this.loadingData = true;
				await this.BookingsService.Post(this.booking.BookingId, null, "Complete");
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":{
		"CancelBooking": "Annulla l'appuntamento",
		"CompleteBooking": "Completa",
		"billingInfo": "Dati di fatturazione"
	}
}
</i18n>
